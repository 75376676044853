/* FONTS CLASS */

.f10 {
	font-size: 10px;
}
.f11 {
	font-size: 11px;
}
.f12 {
	font-size: 12px;
}
.f13 {
	font-size: 13px;
}
.f14 {
	font-size: 14px;
}
.f15 {
	font-size: 15px;
}
.f16 {
	font-size: 16px;
}
.f17 {
	font-size: 17px;
}
.f18 {
	font-size: 18px;
}
.f19 {
	font-size: 19px;
}
.f20 {
	font-size: 20px;
}
.f22 {
	font-size: 22px;
}
.f24 {
	font-size: 24px;
}
.f100 {
	font-weight: 100;
}
.f200 {
	font-weight: 200;
}
.f300 {
	font-weight: 300;
}
.f400 {
	font-weight: 400;
}
.f500 {
	font-weight: 500;
}
.f600 {
	font-weight: 600;
}
.f700 {
	font-weight: 700;
}
.f800 {
	font-weight: 800;
}
.f900 {
	font-weight: 900;
}
.f-bold {
	color: var(--bold);
}
.f-black {
	color: var(--black);
}
.f-blue {
	color: var(--blue);
}
.f-light-blue {
	color: var(--light-blue);
}
.f-yellow {
	color: var(--yellow);
}
.f-default {
	color: var(--default);
}
.f-light {
	color: var(--light);
}
.f-white {
	color: var(--white);
}
.f-grey {
	color: var(--grey);
}
.f-light-grey {
	color: var(--light-grey);
}
.f-red {
	color: var(--red);
}
.f-light-red {
	color: var(--light-red);
}
.f-green {
	color: var(--green);
}
.f-light-green {
	color: var(--light-green);
}
.f-center {
	text-align: center;
}
.f-left {
	text-align: left;
}
.f-right {
	text-align: right;
}
.f-clip {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.f-clip-1 {
	-webkit-line-clamp: 1;
}
.f-clip-2 {
	-webkit-line-clamp: 2;
}
.f-clip-3 {
	-webkit-line-clamp: 3;
}
.f-clip-4 {
	-webkit-line-clamp: 4;
}
.f-clip-5 {
	-webkit-line-clamp: 5;
}

/* BACKGROUNDS CLASS */

.bg-0 {
	background-color: transparent;
}
.bg-bold {
	background-color: var(--bold);
}
.bg-black {
	background-color: var(--black);
}
.bg-blue {
	background-color: var(--blue);
}
.bg-light-blue {
	background-color: var(--light-blue);
}
.bg-yellow {
	background-color: var(--yellow);
}
.bg-default {
	background-color: var(--default);
}
.bg-light {
	background-color: var(--light);
}
.bg-white {
	background-color: var(--white);
}
.bg-grey {
	background-color: var(--grey);
}
.bg-light-grey {
	background-color: var(--light-grey);
}
.bg-red {
	background-color: var(--red);
}
.bg-light-red {
	background-color: var(--light-red);
}
.bg-green {
	background-color: var(--green);
}
.bg-light-green {
	background-color: var(--light-green);
}

/* UTILS */

.trans-1 {
	transition: 0.1s;
}
.trans-2 {
	transition: 0.2s;
}
.trans-3 {
	transition: 0.3s;
}
.trans-4 {
	transition: 0.4s;
}
.trans-5 {
	transition: 0.5s;
}
.trans-6 {
	transition: 0.6s;
}
.trans-7 {
	transition: 0.7s;
}
.trans-8 {
	transition: 0.8s;
}
.trans-9 {
	transition: 0.9s;
}
.trans-10 {
	transition: 1s;
}
.border-0 {
	border: none;
}
.outline-0 {
	outline: none;
}
.shadow-0 {
	box-shadow: none;
}
.d-flex {
	display: flex;
}
.overflow-0 {
	overflow: hidden;
}
.overflow-y-0 {
	overflow-y: hidden;
}
.overflow-x-0 {
	overflow-x: hidden;
}
.padding-0 {
	padding: 0;
}
.margin-0 {
	margin: 0;
}
.p-relative {
	position: relative;
}
.p-absolute {
	position: absolute;
}
.p-fixed {
	position: fixed;
}
.p-sticky {
	position: sticky;
}
.object-cover {
	object-fit: cover;
}
