@charset "UTF-8";
@-ms-viewport {
	width: device-width;
}
@font-face {
	src: url('./fonts/Gilroy-Black.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 900;
}
/* @font-face {
	src: url('./fonts/Gilroy-BlackItalic.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 900;
} */
@font-face {
	src: url('./fonts/Gilroy-Bold.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: bold;
}
/* @font-face {
	src: url('./fonts/Gilroy-BoldItalic.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: bold;
} */
@font-face {
	src: url('./fonts/Gilroy-ExtraBold.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 800;
}
/* @font-face {
	src: url('./fonts/Gilroy-ExtraBoldItalic.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 800;
} */
@font-face {
	src: url('./fonts/Gilroy-Heavy.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: bold;
}
/* @font-face {
	src: url('./fonts/Gilroy-HeavyItalic.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: bold;
} */
/* @font-face {
	src: url('./fonts/Gilroy-LightItalic.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 300;
} */
@font-face {
	src: url('./fonts/Gilroy-Medium.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 500;
}
/* @font-face {
	src: url('./fonts/Gilroy-MediumItalic.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 500;
} */
@font-face {
	src: url('./fonts/Gilroy-Regular.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 400;
}
/* @font-face {
	src: url('./fonts/Gilroy-RegularItalic.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 400;
} */
@font-face {
	src: url('./fonts/Gilroy-SemiBold.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: bold;
}
/* @font-face {
	src: url('./fonts/Gilroy-SemiBoldItalic.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 600;
} */
@font-face {
	src: url('./fonts/Gilroy-Thin.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 100;
}
/* @font-face {
	src: url('./fonts/Gilroy-ThinItalic.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 100;
} */
@font-face {
	src: url('./fonts/Gilroy-UltraLight.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 200;
}
/* @font-face {
	src: url('./fonts/Gilroy-UltraLightItalic.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 200;
} */
@font-face {
	src: url('./fonts/gilroyextrabold.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 800;
}
@font-face {
	src: url('./fonts/gilroylight.woff2');
	font-family: 'Gilroy';
	font-style: normal;
	font-display: swap;
	font-weight: 300;
}
:root {
	--bold: #252a3b !important;
	--black: #09232c !important;
	--blue: #5254f1 !important;
	--light-blue: #b8eeff !important;
	--yellow: #ffe984 !important;
	--default: #e5e5e5 !important;
	--light: #f3f7f9 !important;
	--white: #ffffff !important;
	--grey: #7f8a9e !important;
	--light-grey: #898da61a !important;
	--red: #f35656 !important;
	--light-red: #eb575726 !important;
	--green: #14c7a7 !important;
	--light-green: #14c7a726 !important;
}
* {
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
body {
	font-weight: 500;
	margin: 0;
	color: var(--black);
}
::selection {
	background: var(--blue);
	color: var(--white);
}
::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background: var(--white);
}
::-webkit-scrollbar-thumb {
	background: var(--blue);
}
::-webkit-scrollbar-thumb:hover {
	background: var(--bold);
}
a {
	text-decoration: none !important;
}
ul {
	list-style-type: none !important;
	padding: 0;
}
input,
textarea,
button {
	outline: none !important;
	box-shadow: none !important;
}
body,
input,
textarea,
select,
button {
	font-family: 'Gilroy', sans-serif;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}
textarea {
	resize: none !important;
}
button {
	cursor: pointer;
}
.Toastify__toast {
	font-family: 'Gilroy', sans-serif;
	font-weight: 700;
	border-radius: 8px;
}
.Toastify__progress-bar {
	background-color: var(--white);
}
.Toastify__close-button {
	opacity: 1;
}
.container {
	width: 100% !important;
	box-sizing: border-box !important;
	padding: 0 calc(50% - 600px) !important;
}
@media screen and (max-width: 1440px) {
	.container {
		padding: 0 7% !important;
	}
}
